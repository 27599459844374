import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { flushSync } from "react-dom";
import { toast } from "react-toastify";
import cn from "classnames";
import http from "../../api/http";
import { getErrorNotify } from "../../helpers/getErrorNotify";
import { AddressI, CartI, ProfileI } from "../../types/types";
import { getFirstErrorMessage, debounce } from "../../helpers";
import * as v from "../../helpers/inputValidators";
import "./Cart.scss";
import axios from "axios";

interface FormDataI {
  first_name: string;
  patronymic?: string;
  phone_number: string;
  last_name: string;
  address: {
    address_string: string;
    flat?: number;
    entrance?: number;
    intercom?: string;
    floor?: number;
  };
  email: string;
  data_processing: boolean;
  receive_news: boolean;
}

interface AddressSuggestionI {
  value: string
}

interface SuggestionResponseI {
  suggestions: AddressSuggestionI[]
}

interface ItemI {
  [x: string]: { defaultValue: string; value: string };
}

const Cart = () => {
  const [activeTab, setActiveTab] = useState("cart");
  const [cart, setCart] = useState<CartI | null>(null);
  const [profile, setProfile] = useState<ProfileI | null>(null);
  const [addresses, setAddresses] = useState<AddressI[] | null>(null);
  const [suggestedAddresses, setSuggestedAddresses] = useState<string[]>([]);
  const [items, setItems] = useState<ItemI>({});
  const [itemsColors, setItemsColors] = useState<ItemI>({});
  const navigate = useNavigate();

  // const form = useForm<FormDataI>({
  //   defaultValues: {
  //     email: `skdfhhi${(Math.random()*100).toFixed(0)}@gmail.com`,
  //     first_name: "тест",
  //     patronymic: undefined,
  //     phone_number: "89251234567",
  //     last_name: "тест",
  //     address: {
  //       address_string: "г Москва, ул Дмитрия Ульянова, д 11",
  //       flat: undefined,
  //       entrance: undefined,
  //       intercom: undefined,
  //       floor: undefined,
  //     }
  //   }
  // })

  const form = useForm<FormDataI>({
      defaultValues: {
      email: undefined,
      first_name: "",
      patronymic: undefined,
      phone_number: "",
      last_name: "",
      address: {
        address_string: "",
        flat: undefined,
        entrance: undefined,
        intercom: undefined,
        floor: undefined,
      }
    }
  })

  const fetchCart = async () => {
    const url = `/cart/${localStorage.getItem("cart_id")}`;
    await http
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCart(response?.data);
        let newItems = {};
        let newItemsColors = {};
        response?.data?.items?.forEach((el: any) => {
          //@ts-ignore
          newItems[el.id] = {
            defaultValue: el.amount,
            value: el.amount,
          };
          //@ts-ignore
          newItemsColors[el.id] = {
            defaultValue: el.color,
            value: el.color,
          };
        });
        setItems(newItems);
        setItemsColors(newItemsColors);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchCart();
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      await http
        .get("/account/users/me")
        .then((response) => {
          setProfile(response?.data);
        })
        .catch((error) => console.error(error));
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    const fetchAddress = async () => {
      await http
        .get("/addresses", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setAddresses(response?.data);
        })
        .catch((error) => console.error(error));
    };
    fetchAddress();
  }, []);

  // Fetch address suggestions
  const onAddressChange = debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const response = await axios.post<SuggestionResponseI>("http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", {
      query: e.target.value,
      count: 3
    },{
      headers: {
        "Authorization": "Token d4b518425deeae2fdf25883bbee03d7c8ae641c9" // Whatever, not that private
      }
    })

    setSuggestedAddresses(response.data.suggestions.map((i: AddressSuggestionI) => i.value))
  }, 500)


  const onSubmitionError = (errors: FieldErrors<FormDataI>) => {
    const firstErrorMessage = getFirstErrorMessage(errors)
    firstErrorMessage && toast.error(firstErrorMessage)
  }
  
  const onSubmitFormData = (values: any) => {
    console.log(values)
    setActiveTab("payment")
  };

  const onSubmitPayment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const createOrder = async () => {
      await http
        .post(
          "/orders",
          {
            additional_info: form.getValues(),
            items: cart?.items.map((el) => el.id),
            account_id: profile?.account.id,
            total_price: cart?.items.reduce((value, el) => {
              return value + el?.amount * el?.product?.price;
            }, 0),
          }
        )
        .then(async (response) => {
          const orderId = response.data.order_id

          localStorage.removeItem("newOrderId")
          localStorage.setItem("newOrderId", orderId)
          fetchPayment(orderId)
        })
        .catch((error) => console.error(error));
    };

    const fetchPayment = async (orderId: number) => {
      await http
        .post(
          "/orders/pay",
          {
            orderNumber: orderId,
            amount: cart?.items.reduce((value, el) => {
              return value + el?.amount * el?.product?.price;
            }, 0),
            pageView: window.innerWidth > 600 ? "DESKTOP" : "MOBILE",
            phone: form.getValues("phone_number"),
            email: form.getValues("email"),
          }
        )
        .then((response) => {
          window.location.replace(response.data.payment_link);
        })
        .catch((error) => console.error(error));
    };
    createOrder();
  };

  const deleteItem = async (id: number) => {
    await http
      .delete("/cart/delete_cart_item", {
        params: {
          cart_item_id: id
        }
      })
      .then((response) => {
        toast.success(response.data.message);
        fetchCart();
      })
      .catch((error) => toast.error(getErrorNotify(error)));
  };

  const changeCartItem = async (id: number) => {
    if (items[id].defaultValue !== items[id].value) {
      await http
        .patch(
          "/cart/update_cart_item_props",
          { amount: items[id].value },
          { params: { cart_item_id: id }}
        )
        .then((response) => {
          setItems({
            ...items,
            [id]: { ...items[id], defaultValue: items[id].value },
          });
          toast.success(response.data.message);
          fetchCart();
        })
        .catch((error) => {
          setItems({
            ...items,
            [id]: { ...items[id], value: items[id].defaultValue },
          });

          toast.error(getErrorNotify(error));
        });
    }
  };

  const changeCartItemColor = async (id: number, color: string) => {
    await http
      .patch(
        "/cart/update_cart_item_props",
        {
          color: color,
        },
        {
          params: {
            cart_item_id: id
          }
        }
      )
      .then((response) => {
        setItemsColors({
          ...itemsColors,
          [id]: { ...itemsColors[id], defaultValue: color },
        });
        toast.success(response.data.message);
        fetchCart();
      })
      .catch((error) => {
        setItemsColors({
          ...itemsColors,
          [id]: { ...itemsColors[id], value: color },
        });

        toast.error(getErrorNotify(error));
      });
  };
  
  return (
    <section className={cn("container", "cartPage")}>
      <div className="navList">
        <button
          onClick={() => setActiveTab("cart")}
          className={cn("navListItem", {
            activeListItem: activeTab === "cart",
          })}
        >
          Корзина
        </button>
        <button
          onClick={() => setActiveTab("delivery")}
          className={cn("navListItem", {
            activeListItem: activeTab === "delivery",
          })}
        >
          Доставка
        </button>
        <button
          onClick={() => setActiveTab("payment")}
          className={cn("navListItem", {
            activeListItem: activeTab === "payment",
          })}
        >
          Оплата
        </button>
      </div>
      {activeTab === "cart" && (
        <div className="tableWrapper">
          <div className="cartList">
            {cart?.items.map((el) => {
              return (
                <div className="cartItem" key={el.id}>
                  <div className="imgWrapper">
                    <img src={el.product.images[0]?.link_path} alt="товар" />
                  </div>
                  <div className="info">
                    <div>
                      <p className="title">{el.product.title}</p>
                      <p className="price">{el.product.price} руб.</p>

                      <div className="commonInfo">
                        <form>
                          <select
                            value={itemsColors[el.id].value}
                            onChange={(e) => {
                              flushSync(() =>
                                setItemsColors({
                                  ...itemsColors,
                                  [el.id]: {
                                    ...itemsColors[el.id],
                                    value: e.target.value,
                                  },
                                })
                              );

                              changeCartItemColor(el.id, e.target.value);
                            }}
                          >
                            {el.product?.colors?.map((color) => {
                              return <option value={color} key={color}>{color}</option>;
                            })}
                          </select>
                        </form>
                        <span>/ </span>
                        <form
                          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                            e.preventDefault();
                            changeCartItem(el.id);
                          }}
                        >
                          <label>
                            Кол-во{" "}
                            <input
                              type="text"
                              className="count"
                              placeholder="кол-во"
                              onChange={(e) =>
                                setItems({
                                  ...items,
                                  [el.id]: {
                                    ...items[el.id],
                                    value: Number(e.target.value),
                                  },
                                })
                              }
                              value={items[el.id].value}
                            />
                          </label>
                        </form>
                      </div>
                      <p className="vendor_code">
                        Код: {el.product.vendor_code}
                      </p>
                    </div>
                    <button
                      className="deleteButton"
                      onClick={() => deleteItem(el.id)}
                    >
                      Удалить
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="cartList"></div>
          <div className="buttonsBottom">
            <button onClick={() => navigate("/categores")} className="button">
              Вернуться к покупкам
            </button>
            <button onClick={() => setActiveTab("delivery")} className="button">
              Перейти к доставке
            </button>
          </div>
        </div>
      )}

      {activeTab === "delivery" && (
        <form onSubmit={form.handleSubmit(onSubmitFormData, onSubmitionError)} className="delivery">
          <div className="col1">
            <p className="colName">Контактные данные</p>
            <div className="inputs">
              <input
                className="w100"
                placeholder="Номер телефона"
                type="tel"
                {...form.register("phone_number", v.phoneValidation)}
              />

              <input
                className="w50"
                placeholder="Имя"
                type="text"
                {...form.register("first_name", v.nameValidation)}
              />

              <input
                className="w50"
                placeholder="Отчество"
                type="text"
                {...form.register("patronymic", v.patronymicValidation)}
              />

              <input
                className="w100"
                placeholder="Фамилия"
                type="text"
                {...form.register("last_name", v.nameValidation)}
              />

              <input
                className="w100"
                placeholder="Адрес"
                list="address_string"
                {...form.register("address.address_string", { required: "Укажите адрес" })}
                onChange={onAddressChange}
              />
              <datalist id="address_string">
                {addresses?.map((el) => {
                  return (
                    <option value={el.address_string} key={`addr_${el.id}`}>
                      {el.address_string}
                    </option>
                  );
                })}
                {suggestedAddresses?.map((el) => {
                  return (
                    <option value={el} key={`addr_opt_${el}`}>
                      {el}
                    </option>
                  );
                })}
              </datalist>

              <input
                className="w50"
                placeholder="Квартира"
                list="flat"
                {...form.register("address.flat", v.flatValidation)}
                type="number"
              />
              <datalist id="flat">
                {addresses?.map((el) => {
                  return <option value={el.flat} key={`addr_opt_${el}`}>{el.flat}</option>;
                })}
              </datalist>

              <input
                className="w50"
                placeholder="Этаж"
                list="floor"
                {...form.register("address.floor", v.floorValidation)}
                type="number"
              />
              <datalist id="floor">
                {addresses?.map((el) => {
                  return <option value={el.floor} key={`addr_opt_${el}`}>{el.floor}</option>;
                })}
              </datalist>

              <input
                className="w50"
                placeholder="Подъезд"
                list="entrance"
                {...form.register("address.entrance", v.entranceValidation)}
                type="number"
              />
              <datalist id="entrance">
                {addresses?.map((el) => {
                  return <option value={el.entrance} key={`addr_opt_${el}`}>{el.entrance}</option>;
                })}
              </datalist>
              <input
                className="w50"
                placeholder="Домофон"
                list="intercom"
                {...form.register("address.intercom", v.intercomValidation)}
              />
              <datalist id="intercom">
                {addresses?.map((el) => {
                  return <option value={el.intercom} key={`addr_opt_${el}`}>{el.intercom}</option>;
                })}
              </datalist>
              <input
                className="w100"
                placeholder="Email"
                type="text"
                {...form.register("email", v.emailValidation)}
              />
            </div>
          </div>
          <div className="col2">
            <p className="colName">К оплате</p>
            <div className="item">
              <p>Стоимость товара</p>
              <p>
                {cart?.items.reduce((value, el) => {
                  return value + el?.amount * el?.product?.price;
                }, 0)}{" "}
                ₽
              </p>
            </div>
            <div className="item">
              <p>Стоимость Доставки</p>
              <p>0 ₽</p>
            </div>
            <div className="item">
              <p>Общая сумма</p>
              <p>
                {cart?.items.reduce((value, el) => {
                  return value + el?.amount * el?.product?.price;
                }, 0)}{" "}
                ₽
              </p>
            </div>
            <button type="submit">Перейти к оплате</button>
          </div>
        </form>
      )}
      {activeTab === "payment" && (
        <div className="payment">
          <div className="col1">
            <p className="colName">Срок изготовления</p>
            <p className="text">
              Обращаем ваше внимание, что вся наша мебель изготавливается
              индивидуально по предварительному заказу с внесением полной
              предоплаты. Оставляя заказ на нашем сайте, пожалуйста, учитывайте,
              что в среднем на его выполнение уходит около 30 дней. Спасибо за
              понимание.
            </p>
          </div>
          <div className="col1">
            <p className="colName">Способ оплаты</p>
            <form onSubmit={onSubmitPayment} className="paymentForm">
              <label>
                <input required checked={true} type="checkbox" />
                Картой
              </label>
              <button type="submit">Завершить оформление</button>
            </form>
          </div>
        </div>
      )}
    </section>
  )
}

export default Cart
