import React from 'react';
import { useFetched } from '../../hooks/useFetched';
import './Footer.scss';

type FooterWebInfo = {
    footer: {
        label: string;
        come_to_us: string;
        social_media: {
            resource: string;
            image: string;
        }[];
    };
};

export default function Footer() {
    const [footerInfo] = useFetched<FooterWebInfo>("/main_page/footer");

    if (!footerInfo) return null;

    const { footer } = footerInfo;

    return (
        <footer>
            <div className="wrapper">
                <div className="item">
                    <h3 className="logo">{footer.label}</h3>
                </div>
                <div className="item">
                    <h3>ЗАХОДИТЕ К НАМ</h3>
                    <address>
                        <p className="address">{footer.come_to_us}</p>
                    </address>
                </div>
                <div className="item">
                    <h3>ПРИСОЕДИНЯЙТЕСЬ</h3>
                    <div className="imges">
                        {footer.social_media.map((media) => (
                            <a href={media.resource} key={media.resource}>
                                <img src={media.image} alt="" title="" />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    );
}
