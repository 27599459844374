import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    opened: false,
};

export const addressModalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showModalAddress: (state) => {
            state.opened = true;
        },
        hideModalAddress: (state) => {
            state.opened = false;
        },
        toggleModalAddress: (state) => {
            state.opened = !state.opened;
        },
    },
});

export const { showModalAddress, hideModalAddress, toggleModalAddress } = addressModalSlice.actions;

export default addressModalSlice.reducer;
