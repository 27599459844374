export const debounce = (callback: Function, delay: number = 300) => {
  let timer: NodeJS.Timeout;

  return (...args: any[]) => {

    clearInterval(timer)
    timer = setTimeout(() => {
      callback(...args)
    }, delay)
  }
}