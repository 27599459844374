import React, { useEffect, useState } from "react";
import http from "../../api/http";
import { CategoryPreview } from "../../components";
import { CategoryI } from "../../types/types";
import './CategoriesPage.scss'
import { Helmet } from "react-helmet";
const CategoriesPage: React.FC = () => {
  const [categories, setCategories] = useState<CategoryI[]>(
    []
  );

  useEffect(() => {
    const fetchCategories = async () => {
      await http
        .get("/categories")
        .then((response) => {
          setCategories(response?.data);
        })
        .catch((error) => console.error(error));
    };
    fetchCategories();
  }, []);

  return (
    <section className="categoriesPage">
      <Helmet>
        <title>Декоратория - Каталог</title>
        <link rel="canonical" href="https://decoratoria.ru/categores"></link>
      </Helmet>
      <h2 className="visually-hidden">Каталог товаров</h2>
      <div className="wrapper">
        {categories?.map((el, index) => {
          return <CategoryPreview {...el} key={index} />;
        })}
      </div>
    </section>
  );
};

export default CategoriesPage;
