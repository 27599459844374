import { Helmet } from 'react-helmet';
import useBem from '../../hooks/useBem';
import { useCompleteRegisteration } from '../../hooks/useCompleteRegister';
import './IndexPage.scss';
import MainSection from './views/MainSection';
import NewArrivals from './views/NewArrivals';
import Popular from './views/Popular';
import Subheader from './views/Subheader';

export default function IndexPage() {
  useCompleteRegisteration()
  const { bemBlock } = useBem('IndexPage');

  return (
    <div className={bemBlock()}>
      <main>
        <Helmet>
          <title>магазин мебели Декоратория</title>
          <meta name="robots" content="index"></meta>
          <meta name="description" content="Декоратория - Интернет магазин мебели собственного производства"></meta>
          <link rel="canonical" href="https://decoratoria.ru"></link>
        </Helmet>
        <Subheader />
        <MainSection />
        <NewArrivals />
        <Popular />
      </main>
    </div>
  );
}
