import * as React from 'react';
import Shadow from './shadow/Shadow';
import Basket from './basket/Basket';
import ModalWindow from './ModalWindow/ModalWindow';

const Modals = () => (
    <>
        <ModalWindow />
        {/* <Basket /> */}
        <Shadow />
    </>
);

export default Modals;
