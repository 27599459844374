import * as React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../../store/reducers/modalSlice";
import { toggle } from "../../../store/reducers/basketSlice";

import "./Subheader.scss";
import { useNavigate } from "react-router-dom";

const Subheader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openBasket = () => {
    dispatch(toggle());
  };
  const openModal = () => {
    if (localStorage.getItem("token")) {
      navigate("/profile");
    } else {
      dispatch(showModal());
    }
  };
  return (
    <section className="subheader">
      <div>
        <img
          onClick={openModal}
          className="registration"
          src="icons/registration.png"
          alt="логин"
        />
        <img
          onClick={openBasket}
          className="basket open-closed-basket"
          src="icons/basket.png"
          alt="корзина"
        />
      </div>
    </section>
  );
};

export default Subheader;
