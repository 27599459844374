import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { show } from "../../store/reducers/basketSlice";
import { showModal } from "../../store/reducers/modalSlice";
import {
  ROUTE_ABOUT,
  ROUTE_CART,
  ROUTE_CATEGORIES,
  ROUTE_CONTACTS,
  ROUTE_FAQ,
} from "../../routes";
import "./Header.scss";
import { generateCodeChallenge, generateCodeVerifier } from "../../helpers";
import { isUserLoggedIn } from "../../helpers/jwt";

export default function Header() {
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);

  if (opened) {
    document.body.className = "hidden";
  } else {
    document.body.className = "";
  }

  //@ts-ignore
  const closeBurger = (e) => {
    if (e.target.tagName !== "UL") {
      setOpened(false);
    }
  };

  //@ts-ignore
  const toggleBurger = (e) => {
    if (e.target.tagName !== "UL") {
      setOpened((prevState) => !prevState);
    }
  };

  const logIn = () => {
    let cv = generateCodeVerifier()
    let cc = generateCodeChallenge(cv)

    const callback_uri = encodeURIComponent(`${window.location.origin}/callback`)

    localStorage.removeItem("code-verifier")
    localStorage.setItem("code-verifier", cv)
    location.href = `https://sso.decoratoria.ru/realms/sso_decoratoria/protocol/openid-connect/auth?client_id=internet_store&response_type=code&code_challenge=${cc}&code_challenge_method=S256&redirect_uri=${callback_uri}`
  }

  const handleProfileClick = () => {
    if (isUserLoggedIn()) {
      navigate("/profile")
    } else {
      logIn()
    }
  }

  return (
    <header className="Header">
      <div className={opened ? "wrapper wrapper-active" : "wrapper"}>
        <div className="logo">
          <Link to='/' aria-label="Декоратория"><h1>ДЕКОРАТОРИЯ</h1></Link>
        </div>
        <div className="menu">
          <nav onClick={closeBurger}>
            <ul className="main-ul">
              <li>
                <Link to="/" aria-label="Главная страница">ГЛАВНАЯ</Link>
              </li>
              <li>
                <Link to={ROUTE_CATEGORIES} aria-label="Каталог товаров">КАТАЛОГ</Link>
              </li>
              <li>
                <Link to={ROUTE_ABOUT} aria-label="О магазине Декоратория" >О НАС</Link>
              </li>
              <li>
                <Link to={ROUTE_CONTACTS} aria-label="Контакты магазна Декоратория" >КОНТАКТЫ</Link>
              </li>
              <li>
                <Link to={ROUTE_FAQ} aria-label="Часто задаваемые вопросы" >FAQ</Link>
              </li>
              <li className="icon">
                <div onClick={handleProfileClick} role="button">
                  <img
                    className="registration"
                    src="icons/registration.png"
                    alt="Профиль"
                    title="Профиль"
                  />
                </div>
              </li>
              <li className="icon">
                <Link to={ROUTE_CART}>
                  <div>
                    <img
                      className="basket open-closed-basket"
                      src="/icons/basket.png"
                      alt="Корзина"
                      title="Корзина"
                    />
                  </div>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div onClick={toggleBurger} className="hamburger">
          <span />
          <span />
          <span />
        </div>
      </div>
    </header>
  );
}
