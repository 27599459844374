import { jwtDecode } from "jwt-decode";

export const isUserLoggedIn = () => {
  const token = localStorage.getItem("access-token")
  if (!token) return false

  const decoded = jwtDecode<any>(token)

  // console.log("DECODED TOKEN:", decoded);
  
  if ((decoded.exp - (Date.now() / 1000)) <= 0) {
    localStorage.removeItem("access-token")
    return false
  } 

  return true
} 