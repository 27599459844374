import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../api/http";
import { getErrorNotify } from "../../helpers/getErrorNotify";
import { hideModalAddress } from "../../store/reducers/addressModalSlice";

import { RootState } from "../../store/store";
import { AddressI, ProfileI } from "../../types/types";

import "./NewAddressWindow.scss";

export default function NewAddressWindow() {
  const navigate = useNavigate();
  const modalOpened = useSelector<RootState>(
    (state) => state.addressModal.opened
  );
  const dispatch = useDispatch();

  const closeModal = (): void => {
    dispatch(hideModalAddress());
  };
  
  //@ts-ignore
  const [values, setValues] = useState<AddressI>({});
  const [profile, setProfile] = useState<ProfileI | null>(null);

  useEffect(() => {
    if (modalOpened) {
      document.body.className = "hidden";
    } else {
      document.body.classList.remove("hidden");
    }
  }, [modalOpened]);

  useEffect(() => {
    fetchAddress();
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      await http
        .get("/account/users/me")
        .then((response) => {
          setProfile(response?.data);
        })
        .catch((error) => console.error(error));
    };
    fetchProfile();
  }, []);

  const postAddress = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await http
      .post(
        "/addresses",
        {
          account_id: profile?.account?.id,
          ...values,
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        fetchAddress();
        //@ts-ignore
        setValues({});
        dispatch(hideModalAddress())
      })
      .catch((error) => toast.error(getErrorNotify(error)));
  };

  const fetchAddress = async () => {
    await http
      .get("/addresses")
      .then((response) => {
        // setAddress(response?.data);
      })
      .catch((error) => console.error(error));
  };

  const setFormData = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  return (
    <div className={`modal-window-address ${modalOpened ? "show" : ""}`}>
      <div onClick={closeModal} className="close">
        <div className="box" />
      </div>
      <form onSubmit={postAddress} className="form">
        <div>
          <input
            name="address_string"
            placeholder="Адрес"
            style={{width: '100%', paddingBottom: '20px'}}
            required
            value={values.address_string || ""}
            onChange={setFormData}
          />
          <input
            name="flat"
            placeholder="Квартира"
            required
            type='number'
            value={values.flat || ""}
            onChange={setFormData}
          />
          <input
            name="entrance"
            placeholder="Подъезд"
            required
            type='number'
            value={values.entrance || ""}
            onChange={setFormData}
          />
          <input
            name="intercom"
            placeholder="Домофон"
            required
            value={values.intercom || ""}
            onChange={setFormData}
          />
          <input
            name="floor"
            placeholder="Этаж"
            type='number'
            required
            value={values.floor || ""}
            onChange={setFormData}
          />
        </div>
        <button className="submitButton">Добавить адрес</button>
      </form>
    </div>
  );
}
