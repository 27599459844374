import CryptoJS from "crypto-js"
import base64URL from "crypto-js/enc-base64url"

export function generateCodeVerifier() {
  let rand = new Uint32Array(8)
  rand = crypto.getRandomValues(rand)
  
  // @ts-expect-error init() IS there, but @types/crypto-js doesn't think like that for some reason
  const code_verifier = base64URL.stringify(new CryptoJS.lib.WordArray.init(rand))
  
  return code_verifier
}

export function generateCodeChallenge(code_verifier: string) {
  return base64URL.stringify(CryptoJS.SHA256(code_verifier))
}

