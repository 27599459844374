import React from "react";
import { Link } from "react-router-dom";
import { CategoryI } from "../../types/types";
import './CategoryPreview.scss'

const CategoryPreview: React.FC<CategoryI> = ({ image, title, id }) => {
  return (
    <Link to={`/category?id=${id}`} className="CategoryPreview" aria-label={title}>
      <div className="img-wrapper">
        <img src={image?.link_path} alt={title} />
      </div>
      <div title={title} className="caption">{title}</div>
    </Link>
  );
};

export default CategoryPreview;
