import axios from "axios"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import http from "../../api/http"

/**
 * Юзер попадёт на эту страницу в 2х случаях:
 * 1) Регистрация уже завершена, тогда надо только обменять код на токены и недеректнуть на / 
 * 2) Либо, если регистрация ещё НЕ завершена, тогда нужно редеректнуть его на страницу регистрации
 */

export default function CallbackPage() {
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get('code')
  const cv = localStorage.getItem("code-verifier")

  useEffect(() => {
    if (!code) {
      toast.error("Не получилось войти, попробуйте позже.\n (нету авторизационного кода)")
      window.location.href = window.location.origin 
      throw new Error("Can't exchange authorization code for token pair, because authorization code wasn't provided.")
      return
    }

    if (!cv) {
      toast.error("Не получилось войти, попробуйте позже.")
      window.location.href = window.location.origin 
      throw new Error("Can't exchange authorization code for token pair, because code-verifier doesn't present in the local storage (for some reason).")
      return
    }

    const params = new URLSearchParams()
    params.append('grant_type', 'authorization_code')
    params.append('code', code)
    params.append('client_id', 'internet_store')
    params.append('code_verifier', cv)
    params.append('redirect_uri', `${window.location.origin}/callback`)
    
    axios
      .post("https://sso.decoratoria.ru/realms/sso_decoratoria/protocol/openid-connect/token", 
        params, 
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
        }
      ).then(response => {
          toast.success("Добро пожаловать!")
          localStorage.setItem("access-token", response.data.access_token)
          localStorage.setItem("refresh-token", response.data.refresh_token)
          localStorage.removeItem("code-verifier")

          // Юзер залогинился (не важно, после регистрации или логина, он всега попадёт сюда)
          // Поэтому тут можно проверить, если ли в нашем апи его аккаунт
          // Если да - классно, если нет - то редеректим на форму завершения регистрации
          
          http
            .get("account/users/me")
            .then((res) => {
              console.log("User registered")
              navigate("/")
            })
            .catch(err => {
              console.log("User still not finished registration!")
              toast.warning("Регистрация не завершена!")
              navigate("/complete_registration")
            })
            
        }).catch(err => {
          console.error(err)
          toast.error("Не получилось авторизоваться, попробуйте ещё раз")
          localStorage.removeItem("code-verifier")
          setTimeout(() => {
            navigate("/")
          }, 0)
      }) 
  }, [])

  return <>
    Загрузка...
  </>
}