import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import http from "../../api/http";
import { ProductI } from "../../types/types";
import "./CategoryPage.scss";
const CategoryPage = () => {
  // let { categoryId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let categoryId = searchParams.get("id");

  const [category, setCategory] = useState<ProductI | null>(null);
  const [products, setProducts] = useState<ProductI[] | []>([]);
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  useEffect(() => {
    const fetchCategory = async () => {
      await http
      .get(`/categories/${categoryId}`)
        .then((response: any) => {
          setCategory(response.data)
        })
        .catch((error: any) => console.error(error))
        .finally(() => setIsFetching(false))
    };
    fetchCategory();
  }, []);

  useEffect(() => {
    if (isFetching) {
      const fetchProducts = async () => {
        await http
        .get(`/products?category_id=${categoryId}&limit=24&offset=${currentPage*24}`)
          .then((response: any) => {
            setProducts([...products, ...response.data]);
            setCurrentPage(prev => prev+1)
          })
          .catch((error: any) => console.error(error))
          .finally(() => setIsFetching(false))
      };
      fetchProducts();
    }
  }, [isFetching])
  
  useEffect(() => {
    const onScroll = (e: any) => {
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 530) {
        setIsFetching(true)
      }
    }
    document.addEventListener('scroll', onScroll)
  
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <div className="container categoryPage">
      <h2>{category?.title}</h2>
      <div className="wrapper">
        {products?.map((el) => {
          return (
            <Link to={`/product?id=${el.id}`} className="card" key={`prod_${el.id}_${el.title}`}>
              <div className="classImg">
                <img
                  src={el.images[0]?.link_path}
                  alt={el.title}
                  title={el.title}
                />
              </div>
              <div className="name-price">
                <p className="price">{el.price} руб.</p>
                <div className="will-appear-smoothly">
                  <p>Подробности</p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryPage;
