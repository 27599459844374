import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../api/http";
import { getErrorNotify } from "../../helpers/getErrorNotify";

import { hideModal } from "../../store/reducers/modalSlice";
import { RootState } from "../../store/store";

import "./ModalWindow.scss";

export default function ModalWindow() {
  const navigate = useNavigate();
  const modalOpened = useSelector<RootState>((state) => state.modal.opened);
  const [context, setContext] = useState("login");
  const [login, setLogin] = useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });
  const [reg, setReg] = useState<{
    email: string;
    password1: string;
    password2: string;
    first_name: string;
    last_name: string;
    patronymic: string;
    phone_number: string;
  }>({
    email: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
    patronymic: "",
    phone_number: "",
  });
  const dispatch = useDispatch();

  const closeModal = (): void => {
    dispatch(hideModal());
  };
  const setLoginData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };
  const setRegData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReg({ ...reg, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (modalOpened) {
      document.body.className = "hidden";
    } else {
      document.body.classList.remove("hidden");
    }
  }, [modalOpened]);

  // const createCart = async (account_id: number) => {
  //   await http
  //     .post("/cart/create_cart/?account_id")
  //     .then((response) => {
  //       localStorage.setItem("token", response.data?.access_token);
  //       localStorage.setItem("timeStamp", String(Date.now()));
  //       toast.success("Добро пожаловать");
  //       closeModal();
  //     })
  //     .catch((error) => toast.error(getErrorNotify(error)));
  // };

  const submitFormLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append("username", login.email);
    params.append("password", login.password);
    params.append("scope", "current_user");
    const sendLogin = async () => {
      await http
        .post("/account/login", params, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          localStorage.setItem("token", response.data?.access_token);
          localStorage.setItem("timeStamp", String(Date.now()));
          toast.success("Добро пожаловать");
          navigate('/profile')
          closeModal();
        })
        .catch((error) => toast.error(getErrorNotify(error)));
    };
    sendLogin();
  };

  const submitFormReg = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const sendReg = async () => {
      await http
        .post("/account/users", reg)
        .then((response) => {
          setContext("login");
          toast.success("Вы успешно зарегистрировались");
        })
        .catch((error) => toast.error(getErrorNotify(error)));
    };
    sendReg();
  };

  return (
    <div className={`modal-window ${modalOpened ? "show" : ""}`}>
      <div onClick={closeModal} className="close">
        <div className="box" />
      </div>
      {context === "login" ? (
        <>
          <h3>Вход в личный кабинет</h3>
          {/* <p>Введите адрес почты</p> */}
          <form onSubmit={submitFormLogin}>
            <label>
              <input
                value={login.email}
                onChange={setLoginData}
                required
                placeholder="email"
                type="text"
                name="email"
              />
            </label>
            <label>
              <input
                value={login.password}
                onChange={setLoginData}
                required
                placeholder="пароль"
                type="password"
                name="password"
              />
            </label>
            <button className="sendButton">Продолжить</button>
            <button
              onClick={() => setContext("registration")}
              className="registration"
              type="button"
            >
              Регистрация на сайте
            </button>
          </form>
        </>
      ) : (
        <>
          <h3>Регистрация</h3>
          <form onSubmit={submitFormReg}>
            <label>
              <input
                value={reg.email}
                onChange={setRegData}
                required
                placeholder="Email"
                type="text"
                name="email"
              />
            </label>
            <label>
              <input
                value={reg.password1}
                onChange={setRegData}
                required
                placeholder="Пароль"
                type="password"
                name="password1"
              />
            </label>
            <label>
              <input
                value={reg.password2}
                onChange={setRegData}
                required
                placeholder="Повторите пароль"
                type="password"
                name="password2"
              />
            </label>
            <label>
              <input
                value={reg.first_name}
                onChange={setRegData}
                required
                placeholder="Имя"
                type="text"
                name="first_name"
              />
            </label>
            <label>
              <input
                value={reg.last_name}
                onChange={setRegData}
                required
                placeholder="Фамилия"
                type="text"
                name="last_name"
              />
            </label>
            <label>
              <input
                value={reg.patronymic}
                onChange={setRegData}
                placeholder="Отчество"
                type="text"
                name="patronymic"
              />
            </label>
            <label>
              <input
                value={reg.phone_number}
                onChange={setRegData}
                placeholder="Номер телефона"
                type="tel"
                required
                name="phone_number"
              />
            </label>
            <button className="sendButton">Продолжить</button>
            <button
              onClick={() => setContext("login")}
              className="registration"
              type="button"
            >
              Логин
            </button>
          </form>
        </>
      )}
    </div>
  );
}
