import { useEffect } from "react"
import http from "../../api/http"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import "./PaymentSuccessPage.scss"

export default function PaymentSuccessPage() {
  const navigate = useNavigate()

  useEffect(() => {
    const orderId = localStorage.getItem("newOrderId")

    if (!orderId) {
      console.error("/payment_success endpoint was hit, but there is no stored order id.")
      toast.error("Не удалось получить номер заказа.")
      navigate('/')
      return
    }

    http
      .put(`/orders/after_pay?success=true&order_id=${orderId}`)
      .then(() => {
        setTimeout(() => {
          navigate(`/order?id=${orderId}`)
        }, 2000);
        localStorage.removeItem("newOrderId")
      })
      .catch((error) => console.error(error))
  }, [])

    return (
      <div className="container center">
        <h1>Заказ оплачен!</h1>
      </div>
    )
}
