import * as React from 'react';
import Shadow from './shadow/Shadow';
import Basket from './basket/Basket';
import NewAddressWindow from './NewAddressWindow/NewAddressWindow';

const Modals = () => (
    <>
        <NewAddressWindow />
        
        <Shadow />
    </>
);

export default Modals;
