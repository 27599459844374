import React from "react";
import { Link } from "react-router-dom";
import { ProductI } from "../../types/types";
import './ProductPreview.scss'


const ProductPreview: React.FC<ProductI> = ({ images, title, id }) => {
  return (
    <Link to={`/product?id=${id}`} className="productPreviewItem">
      <img src={images[0]?.link_path} alt={title} />
    </Link>
  );
};

export default ProductPreview;
