import { combineReducers, configureStore } from "@reduxjs/toolkit";
import addressModal from "./reducers/addressModalSlice";

import basketReducer from "./reducers/basketSlice";
import modalReducer from "./reducers/modalSlice";

const rootReducer = combineReducers({
  basket: basketReducer,
  modal: modalReducer,
  addressModal: addressModal,
});

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
