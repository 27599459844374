import { FieldErrors } from "react-hook-form"

type NestedErrors = FieldErrors<any> | {[key: string]: any }

// Returns a first error message in nested object or undefined
export const getFirstErrorMessage = (errors: NestedErrors): string | undefined => {
  for (const key in errors) {
    const error = errors[key]
    if (error.message) {
      return error.message
    } else {
      const nestedMessage = getFirstErrorMessage(error as NestedErrors)
      if (nestedMessage) {
        return nestedMessage
      }
    }
  }
  
  return undefined
}
