import { useEffect } from "react"
import http from "../../api/http"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import "./PaymentFailurePage.scss"

export default function PaymentFailurePage() {
  const navigate = useNavigate()

  useEffect(() => {
    const orderId = localStorage.getItem("newOrderId")

    if (!orderId) {
      console.error("/payment_failure endpoint was hit, but there is no stored order id.")
      toast.error("Не удалось получить номер заказа.")
      navigate('/')
      return
    }

    http
      .put(`/orders/after_pay?success=false&order_id=${orderId}`)
      .then(() => {
        localStorage.removeItem("newOrderId")
      })
      .catch((error) => console.error(error))
  }, [])

    return (
      <div className="container center">
        <h1>Не удалось оплатить заказ!</h1>
        <Link to="/">На главную</Link>
      </div>
    )
}
