import { useState, useEffect } from "react";
import http from "../api/http";

export const useFetched = <T>(url: string, defaultValue?: T) => {
  const [data, setData] = useState<T | undefined>(defaultValue);

  useEffect(() => {
    http
      .get(url)
      .then((res) => setData(res.data))
      .catch(console.log);
  }, []);

  return [data];
};
