import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { getErrorNotify } from "../../helpers/getErrorNotify";
import { ImageI, ProductI } from "../../types/types";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import http from "../../api/http";

import DolyamiIcon from "../../../public/icons/dolyami.svg";
import SBPIcon from "../../../public/icons/SBP.svg";
import TinkIcon from "../../../public/icons/tink.svg";
import ArrowIcon from "../../../public/icons/arrowSidebar.svg";
import "./ProductPage.scss";

const getStructuredData = (product: ProductI | null) => {
  if (!product) return ""

  return {
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": product.title,
    "image": product.images[0].link_path,
    "description": product.description,
    "url": location.href,
    "offers": {
        "@type": "Offer",
        "price": product.price,
        "priceCurrency": "RUB",
        "availability": product.is_available ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
        "seller": {
            "@type": "Organization",
            "name": "Декоратория"
        }
    }
  }
}

const ImagePreview: React.FC<{ url?: string }> = ({ url }) => {
  const imgRef = useRef<HTMLDivElement>(null);

  const onMove: MouseEventHandler<HTMLDivElement> = (e) => {
    // const [x, y] = [e.nativeEvent.offsetX, e.nativeEvent.offsetY]
    // const zoomer = e.currentTarget;
    // const xx = x / zoomer.offsetWidth * 100
    // const yy = y / zoomer.offsetHeight * 100
    // if (imgRef.current) imgRef.current.style.backgroundPosition = xx + '% ' + yy + '%'
  };

  const reset = () => {
    if (imgRef.current) imgRef.current.style.backgroundPosition = "50% 50%";
  };

  return (
    <div
      ref={imgRef}
      className="image_main"
      style={{ background: `url(${url}) 50% 50%` }}
      onMouseMove={onMove}
      onMouseLeave={reset}
    ></div>
  );
};

const ProductPage = () => {
  let [searchParams] = useSearchParams();
  const [count, setCount] = useState(1);
  const [color, setColor] = useState("");
  const [product, setProduct] = useState<ProductI | null>(null);
  const [currentImg, setCurrentImg] = useState<ImageI | undefined>(undefined);
  const tabContentRef = useRef<HTMLDivElement>(null)
  let productId = searchParams.get("id");
  
  useEffect(() => {
    const fetchProduct = async () => {
      await http
        .get(`/products/${productId}`)
        .then((response) => {
          setProduct(response?.data);
          setCurrentImg(response?.data.images[0]);
          setColor(response?.data?.colors[0]);
        })
        .catch((error) => toast.error(getErrorNotify(error)));
    };
    fetchProduct();
  }, []);

  const addToCart = async () => {
    await http
      .post(`/cart/add_item`, {
        product_id: product?.id,
        amount: count,
        cart_id: localStorage.getItem("cart_id"),
        color: color,
      })
      .then((response) => {
        toast.success(<p>{response.data.message}</p>);
      })
      .catch((error) => toast.error(getErrorNotify(error)));
  };

  const scrollPicture = (count: number) => {
    const imgLen = product?.images.length || 0;
    const currIdx = product?.images.indexOf(currentImg as any) || 0;
    const newIdx = (currIdx + imgLen + count) % imgLen;
    setCurrentImg(product?.images[newIdx]);
  };

  const handleDelegatedTabClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as HTMLParagraphElement
    if (!target.getAttribute("data-tab")) return // if wrapper was clicked instead of tab button

    // corressponding content block for clicked tab 
    const targetParagraph = document.querySelector(`p[data-related-tab=${target.getAttribute("data-tab")}]`) as HTMLParagraphElement
    
    // if active tab was clicked again - close it
    if (targetParagraph.classList.contains("tab-active")) {
      tabContentRef.current!.style.height = "0px"
      setTimeout(() => {
        targetParagraph.classList.remove("tab-active")
      }, 400)
      return
    }


    tabContentRef.current?.classList.add("disappear")

    setTimeout(() => {
      tabContentRef.current!.style.transitionDuration = "0.3s"
      document.querySelector("p.tab-active")?.classList.remove("tab-active")
      targetParagraph.classList.add("tab-active")
      tabContentRef.current!.style.height = `${targetParagraph.scrollHeight}px`
    }, 200)
    
    setTimeout(() => {
      tabContentRef.current?.classList.remove("disappear")
    }, 200)
      
  }

  return (
    <section className="productPage container">

      <Helmet>
        <title>{product?.title || "Декоратория"}</title>
        <meta name="description" content={product?.description || ""}/>
        <script type="application/ld+json">{ JSON.stringify(getStructuredData(product))}</script>
      </Helmet>

      <div className="productBlock">
        <div className="images">
          <div className="image-container">
            <ArrowIcon
              onClick={() => scrollPicture(-1)}
              style={{ rotate: "180deg", cursor: "pointer", width: 20 }}
            />
            <ImagePreview url={currentImg?.link_path} />
            <ArrowIcon
              onClick={() => scrollPicture(1)}
              style={{ cursor: "pointer", width: 20 }}
            />
            <div className="empty"></div>
            <ul className="imagesSlider">
              {product?.images.map((el) => {
                return (
                  <li key={`img_${el.link_path}_${el.id}`}>
                    <img
                      onClick={() => setCurrentImg(el)}
                      className="image"
                      key={el.id}
                      src={el.link_path}
                      alt={product.title}
                    />
                  </li>
                );
              })}
              {product?.design?.link_path && (
                <li>
                  <img
                    onClick={() => setCurrentImg(product?.design)}
                    className="image"
                    src={product?.design?.link_path}
                    alt="чертёж"
                  />
                </li>
              )}
            </ul>
            <div className="empty"></div>
          </div>

        </div>
        <div className="productInfo">
          <h2 className="name">{product?.title}</h2>
          <p>
            <span className="bold">Цена:</span> {product?.price} руб.
          </p>
          <div>
            <p className="bold">Цвет</p>
            <select value={color} onChange={(e) => setColor(e.target.value)} className="select">
              {product?.colors?.map((el) => {
                return (
                  <option key={el} value={el}>
                    {el}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <p className="bold">Количество</p>
            <input
              value={count}
              onChange={(e) => setCount(Number(e.target.value))}
              className="input"
              min={1}
              type="number"
            />
          </div>
          <button disabled={!product?.is_available} onClick={() => addToCart()} className="add">
            Добавить в корзину
          </button>
          <p>
            <span className="bold">Артикул:</span> {product?.vendor_code}
          </p>
          <p>
            <span className="bold">Размер:</span> {product?.size}
          </p>
          <p>
            <span className="bold">Материал:</span> {product?.material}
          </p>
          <p>
            <span className="bold">Вес:</span> {product?.weight}
          </p>
          <p className="description">{product?.description}</p>
          <a
            onClick={() => {
              if (!product?.design?.link_path) {
                toast.warn(<p>Чертеж для данного товара отсутствует</p>);
              }
            }}
            href={product?.design?.link_path}
            download
            className="downloadDesign"
          >
            скачать чертёж
          </a>
        </div>
      </div>


      <div className="generalProductDetails">
        <div className="detailsTabs" onClick={handleDelegatedTabClick}>
          <div>
            <button data-tab="placement">ОФОРМЛЕНИЕ ЗАКАЗА</button>
            <button data-tab="delivery">ДОСТАВКА</button>
          </div>
          <div>
            <button data-tab="exploitation">ЭКСПЛУАТАЦИЯ</button>
            <button data-tab="fabrics">ТКАНИ</button>
          </div>
          <div>
            <button data-tab="paying">ОПЛАТА</button>
            <button data-tab="packaging">УПАКОВКА</button>
          </div>
          <div>
            <button data-tab="productionTime">СРОКИ ПРОИЗВОДСТВА</button>
          </div>
        </div>
          <div className="detailsContent description" ref={tabContentRef}>
            {/* selected tab's content goes here */}
            <p data-related-tab="placement">
              Оформить заказ можно в пару кликов через корзину, поместив в нее интересующие товары.
            </p>
            <p data-related-tab="delivery">
              Доставка, разгрузка, подъем на этаж и сборка не включены в стоимость изделий.
            <br/><br/>
              По умолчанию мы организуем доставку одной из сторонних транспортных компаний, с которыми работаем регулярно. Заказчику необходимо принять груз и оплатить доставку напрямую ТК — наличными при получении или по счету заранее.
            <br/><br/>
              Стоимость междугородней доставки зависит от объема заказа и расстояния перевозки. Она рассчитывается ТК после забора груза. Мы можем рассчитать ориентировочную стоимость после того, как получим полный состав заказа.
            <br/><br/>
              Мы работаем с ТК, которые проявили себя как надежные и пунктуальные. Но заказчик может оформить доставку любой удобной ему компанией или самостоятельно забрать груз с нашего производства.
            <br/><br/>
              Грузы подлежат обязательному страхованию. В случае повреждения во время доставки ТК выплачивает компенсацию заказчику. Мы, в свою очередь, производим новый предмет/деталь или помогаем найти способ отреставрировать их дистанционно.

            </p>
            <p data-related-tab="exploitation">
              В процессе эксплуатации изделий неизбежно появление незначительных царапин и истираемость покрытия. На тканях допустимо появление заломов, складок и катышек.
              <br/><br/>
              Изделие не предназначено для использования на улице.
              <br/><br/>
              <strong>Уход:</strong><br/>
              &nbsp; &nbsp;- изделия в обивке можно протирать влажной тканью, чистить пылесосом и производить сухую химчистку<br/>
              &nbsp; &nbsp;- не рекомендуется: стирка, влажная химчистка, отбеливание, глажка<br/>
              &nbsp; &nbsp;- не рекомендуется располагать изделие ближе 60 см от источника тепла<br/>
            </p>
            <p data-related-tab="paying">
              Мы работаем со 100% предоплатой. Оплатить заказ, оформленный через наш сайт или почту, можно по счету для юридических и по ссылке для физических лиц. В шоуруме мы принимаем банковские карты и наличные.<br/><br/>
              <div className="paymentIcons">
                <DolyamiIcon /><TinkIcon/><SBPIcon/>
              </div>
            </p>
            <p data-related-tab="fabrics">
              Ткани: рогожка / велюр / букле<br/>
              Производитель: Турция<br/>
              Состав: полиэстер<br/>
              Плотность: 230 - 470 г/м²<br/>
              Износостойкость: 50 000 - 115 000 циклов<br/>
            </p>
            <p data-related-tab="packaging">
              Для доставки по Санкт-Петербургу и Ленинградской области мы упаковываем стулья в фирменные коробки, а остальную мебель в стретч-пленку.
              <br/><br/>
              Для междугородних перевозок предназначена дополнительная упаковка, которая не включена в стоимость мебели и рассчитывается отдельно, в зависимости от направления и вида перевозки. Она состоит из уплотненного полиэтилена двух видов и стретч-пленки и предназначена для защиты груза от царапин и легких механических повреждений. Индивидуальная упаковка позволяет компактно расположить груз и сократить стоимость доставки приблизительно в 2 раза.
            </p>
            <p data-related-tab="productionTime">
                <span className="bold">От {product?.term_production || 25 } рабочих дней</span> (сроки производства могут изменяться от загруженности производства и объёма изделий).
            </p>
          </div>
        </div>
    </section>
  );
};

export default ProductPage;
