import { RegisterOptions } from "react-hook-form";

const stringNullByDefault = (value: any) => {
  if (value?.trim() === "") return null
  return value
}

const numberNullByDefault = (value: any) => {
  const numValue = parseInt(value)
  return isNaN(numValue) ? null : numValue
}

export const phoneValidation: RegisterOptions = {
  required: {
    value: true,
    message: "Не указан номер телефона"
  },
  maxLength: {
    value: 11,
    message: "Неверный формат телефона"
  },
  pattern: {
    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
    message: "Неверный формат телефона!"
  }
}

export const emailValidation: RegisterOptions = {
  required: {
    value: true,
    message: "Не указан Email"
  },
  pattern: {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    message: "Неверный формат Email"
  }
}

export const nameValidation: RegisterOptions = {
  required: {
    value: true,
    message: "Не указано имя или фамилия"
  }
}

export const patronymicValidation: RegisterOptions = {
  setValueAs: stringNullByDefault
}

export const floorValidation: RegisterOptions = {
  setValueAs: numberNullByDefault,
  min: {
    value: 0,
    message: "Этаж не может быть отрицательным"
  }
}

export const flatValidation: RegisterOptions = {
  setValueAs: numberNullByDefault,
  min: {
    value: 0,
    message: "Номер квартиры/офиса не может быть отрицательным"
  }
}

export const entranceValidation: RegisterOptions = {
    setValueAs: numberNullByDefault,
    min: {
      value: 0,
      message: "Номер подъезда не может быть отрицательным"
    }
}

export const intercomValidation: RegisterOptions = {
  setValueAs: stringNullByDefault
}