import * as React from 'react';
import useBem from '../../hooks/useBem';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Modals from '../../modals/Modals';
import NewAddressModal from '../../modals/NewAddressModal';

interface ILayoutProps {
    children?: React.ReactNode,
}

export default function Layout(props: ILayoutProps) {
    const {bemBlock} = useBem('Layout');
    return (
        <div className={bemBlock()}>
            <div id='wrap'>
                <Header />
                {props.children}

            </div>
            <Footer />
            <Modals />
            <NewAddressModal />
        </div>
    );
}
