import * as React from "react";
import "./СontactsPage.scss";
import { Helmet } from "react-helmet";

export default function ContactsPage() {
  return (
    <main>
      <Helmet>
        <title>Наши Контакты</title>
        <link rel="canonical" href="https://decoratoria.ru/contacts"></link>
        <meta name="robots" content="index"></meta>
        <meta name="description" content="Остались вопросы - Свяжитесь с нами удобным способом, или приходите в гости! Всегда ждём вас в нашем Шоу-руме OM STUDIO или прямо в офисе!"></meta>
      </Helmet>
      <section className="contacts">
        <h2>Контакты</h2>
        <article className="wrapper">
          <div className="map">
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Af546ec1924a74459fa3ef916515a2589d730c2cc96de38613965aa7bb48a1785&amp;source=constructor"
              width="100%"
              height="400"
              frameBorder="0"
            />
          </div>
          <address className="navigation">
            <h3>Наш офис</h3>
            <p className="address">Россия, Москва, Дубравная улица, 50к5</p>
            <p className="workMode">
              Режим работы: <br />
              ПН-ПТ 09:00-18:00; ВС-СБ выходной
            </p>
            <a href="tel:+74957285821"> +7(495) 728-58-21</a>

            <a href="mailto:box@decoratoria.ru">box@decoratoria.ru</a>
          </address>
        </article>

        <article className="wrapper">
          <div className="map">
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A331374f84466af43d772caac75f263fd181ed8245fe497243b5ab7238f1e026d&amp;source=constructor"
              width="100%"
              height="400"
              frameBorder="0"
            />
          </div>
          <address className="navigation">
            <h3>Шоу-рум OM STUDIO</h3>
            <p className="address">
              Россия, Москва,
              <br />
              ЦД "Artplay", ул. Нижняя Сыромятническая, 10, стр.9, вход Е / 52, этаж 1, офис 114
            </p>
            <p className="workMode">
              Режим работы: <br />
              Понедельник-Пятница 10.00-20.00 Суббота 11.30-18.00 Воскресенье 12.00-18.00
            </p>
            <a href="tel:+74999952617">+7 (499) 995-26-17</a>
            <a href="mailto:omstudio@srpremium.ru">omstudio@srpremium.ru</a>
          </address>
        </article>
      </section>
    </main>
  );
}
