import { useEffect, useState } from 'react';
import http from '../../../api/http';
import { CategoryPreview } from '../../../components';
import { CategoryI } from '../../../types/types';
import './MainSection.scss';

export const MainSection = () => {
  const [list, setlist] = useState<CategoryI[] | undefined>(undefined)

  useEffect(() => {
    const fetchList = async () => {
      await http
        .get('/categories/main_page_categories')
        .then(response => {
          setlist(response?.data?.slice(0, 8))
        })
        .catch(error => console.error(error));
    };
    fetchList()
  }, [])

  return (
    <section className="main">
      <div className="wrapper">
        {list?.map((el) => {
          return <CategoryPreview {...el} key={`cat_${el.title}`} />
        })}
      </div>
    </section>
  )
}

export default MainSection;