import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../api/http";
import "./CompleteRegisterPage.scss"
import { jwtDecode } from "jwt-decode";

export default function CompleteRegisterPage() {
  const navigate = useNavigate()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const token = localStorage.getItem("access-token")
    if (!token) {
      toast.error("Произошла ошибка, пожалуйста перезайдите в аккаунт")
      setTimeout(() => {
        navigate("/logout") // TODO: Not tested yet
      }, 3000);
      return 
    }

    const decodedJWT = jwtDecode<any>(token)
    
    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    
    const data = {
      email: decodedJWT.email,
      password1: "0459fj908354fh9458f45",
      password2: "0459fj908354fh9458f45",
      first_name: formData.get("first_name"),
      last_name: formData.get("last_name"),
      patronymic: formData.get("patronymic") || null,
      phone_number: formData.get("phone_number")
    }

    // console.log("Запрос на создание пользователя:\n", data);
    
    http
      .post("account/users", data)
      .then(() => {
        toast.success("Успешно!")
        navigate("/")
      })
  }

  return (
    <div className="register">
      <h2>Завершите регистрацию</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <input
            required
            placeholder="Имя"
            name="first_name"
          />
        </label>
        <label>
          <input
            required
            placeholder="Фамилия"
            name="last_name"
          />
        </label>
        <label>
          <input
            placeholder="Отчество"
            name="patronymic"
          />
        </label>
        <label>
          <input
            placeholder="Номер телефона"
            type="tel"
            required
            name="phone_number"
          />
        </label>
        <button className="registration" type="submit">Завершить</button>
        <Link to="/logout" className="registration" style={{color: "black", fontSize: "1rem"}}>Выйти из аккаунта</Link>
      </form>
    </div>
  )
}