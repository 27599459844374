import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import http from "../../api/http";
import { FullOrderI, OrderI } from "../../types/types";
import "./OrderPage.scss";
const OrderPage: React.FC = () => {
  const [order, setOrder] = useState<FullOrderI | null>(null);
  
  let [searchParams] = useSearchParams();
  let orderId = searchParams.get("id");
  
  useEffect(() => {
    const fetchOrder = async () => {
      await http
        .get("/orders/" + orderId + "/?load_customer_info=false", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setOrder(response?.data);
        })
        .catch((error) => console.error(error));
    };
    fetchOrder();
  }, []);

  console.log(order);
  return (
    <section className="orderPage container">
      <div className="mainInfo">
        <h2>Заказ №{order?.id}</h2>
        <p>
          <span className="bold">Стоимость: </span>
          {order?.total_price} руб.
        </p>
        <p>
          <span className="bold">Статус: </span>
          {order?.status}
        </p>
      </div>
      <div className="tableWrapper">
        <table>
          <thead>
            <tr>
              <th>Товар</th>
              <th>Наименование</th>
              <th>Количество</th>
            </tr>
          </thead>
          <tbody>
            {order?.items.map((el) => {
              return (
                <tr key={`ord_${el.id}_${el?.product?.title}`}>
                  <td>
                    <img src={el.product.images[0]?.link_path} alt="товар" />
                  </td>
                  <td>{el.product.title}</td>
                  <td>{el.amount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default OrderPage;
