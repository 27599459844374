/**
 * @description Этот хук проверит, если юзер авторизовался через Keycloak,
 * но всё ещё не имеет аккаунта в нашем апи. В этом случае,
 * будет выполнен редерект на /complete_registration
 */

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { isUserLoggedIn } from "../helpers/jwt"
import http from "../api/http"
import { toast } from "react-toastify"

export const useCompleteRegisteration = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isUserLoggedIn()) {
      http
        .get("account/users/me")
        .catch(err => {
          console.log("KK - Registered, API - Not")
          toast.warning("Регистрация не завершена!")
          navigate("/complete_registration")
        })
    }  
  }, [])
}