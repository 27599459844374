import * as React from "react";
import "./AboutUsPage.scss";
import { Helmet } from "react-helmet";

export default function AboutUsPage() {
  return (
    <section className="aboutUs">
      <Helmet>
        <title>Декоратория - О Нас</title>
        <link rel="canonical" href="https://decoratoria.ru/about"></link>
        <meta name="robots" content="index"></meta>
      </Helmet>
      <div className="wrapper">
        <div className="img">
          <img
            src="https://i.picsum.photos/id/237/536/354.jpg?hmac=i0yVXW1ORpyCZpQ-CknuyV-jbtU7_x9EBQVhvT5aRr0"
            alt=""
            title=""
          />
        </div>
        <div className="textWrapper">
          <h2>О проекте</h2>
          <div className="title">
            <p>
              Компания Декоратория существует на рынке более 20 лет и занимается
              изготовлением уникальной мебели и архитектурных объектов,
              воплощающих в себе самые передовые идеи в этой области. Наша
              команда создала не одну выставочную площадку в таких известных
              местах Москвы и Петербурга, как ВДНХ, музеи Гараж, Царицыно и
              Эрмитаж. Были разработаны и созданы эксклюзивные конструкции, в
              которых использовались разные виды материалов. Собственные
              технологии и производство позволили воплотить в жизнь самые смелые
              и креативные идеи.
            </p>

            <p>
              Многолетний опыт вдохновил нас на изобретение и конструирование
              особенных продуманных предметов мебели, в которых сочетается
              лаконичность и максимальная практичность. Каждая конструкция - это
              не просто стол или табурет, это уникальный предмет, созданный с
              учетом наивысшего удобства, эргономики и свойств выбранных
              материалов. Цель нашей команды – помочь Вам создать собственное
              комфортное и продуманное до мелочей пространство жизни!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
