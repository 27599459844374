import React, { useEffect, useState } from 'react';
import http from '../../../api/http';
import { ProductPreview } from '../../../components';
import { PopProductI } from '../../../types/types';
import './Popular.scss';

const Popular = () => {
    const [list, setlist] = useState<PopProductI[] | undefined>(undefined)

    useEffect(() => {

        const fetchList = async () => {
            await http
                .get('/main_page/pop_products')
                .then(response => {
                    setlist(response?.data?.slice(0, 8))
                    // console.log(response)
                })
                .catch(error => console.error(error));
        };
        fetchList()

    }, [])

    return <section className="popular">
        <h2>
            ПОПУЛЯРНЫЕ ТОВАРЫ
        </h2>
        <div className="wrapper">
            {list?.map((el) => {
                return <ProductPreview key={el.id} {...el.product} />
            })}
        </div>
    </section>
};

export default Popular;
