import { useEffect, useState } from 'react';
import useBem from '../../../hooks/useBem';

import './NewArrivals.scss';
import http from '../../../api/http';
import { ProductI } from '../../../types/types';
import { Link } from 'react-router-dom';

export default function NewArrivals() {
  const { bemBlock } = useBem('NewArrivals');
  const [list, setlist] = useState<ProductI[] | undefined>(undefined)

  useEffect(() => {
    const fetchList = async () => {
      await http
        .get('/products/new_products')
        .then(response => {
          setlist(response?.data?.slice(0, 8))
        })
        .catch(error => console.error(error));
    };
    fetchList()
  }, [])

  return (
    <section className={bemBlock()}>
      <h2>
        НОВЫЕ ПОСТУПЛЕНИЯ
      </h2>
      <div className="wrapper">
        {list?.map((el) => {
          return (
            <Link to={`/product?id=${el.id}`} className="card" key={el.id} aria-label={el.title}>
              <div className="classImg">
                <img
                  src={el.images[0]?.link_path}
                  alt={el.title}
                  title={el.title}
                />
              </div>
              <div className="name-price">
                <p className="name">
                  УЗНАЙТЕ БОЛЬШЕ!
                </p>
                <p className="price">
                  {el.price}
                </p>
                <div className="will-appear-smoothly">
                  <p>
                    Подробности
                  </p>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </section>
  );
}
