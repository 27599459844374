import "!style-loader!css-loader!react-toastify/dist/ReactToastify.css";
import { useCallback, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import http from "./api/http";
import { publicRoutes } from "./routes";
import Layout from "./shared/Layout";
import { Helmet } from "react-helmet";


export default function Application() {
  // TODO: Move to useCart hook
  useEffect(() => {
    if (!localStorage.getItem("cart_id")) {
      http
        .post(`/cart/create_cart`)
        .then((response) => {
          localStorage.setItem("cart_id", response?.data?.id);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  const getPublicRoutes = useCallback(() => (
        publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))
      ),
    [publicRoutes]
  );

  return (
    <BrowserRouter>
      <Layout>
        <ToastContainer hideProgressBar={true} autoClose={2500} theme="colored" />{" "}
        <Routes>{getPublicRoutes()}</Routes>
      </Layout>
    </BrowserRouter>
  );
}
