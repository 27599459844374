import AboutUsPage from './AboutPage/AboutUsPage';
import CallbackPage from './CallbackPage/CallbackPage';
import Cart from './Cart';
import CategoriesPage from './CategoriesPage';
import CategoryPage from './CategoryPage';
import CompleteRegisterPage from './CompleteRegisterPage/CompleteRegisterPage';
import ContactsPage from './ContactsPage/ContactsPage';
import FaqPage from './FaqPage/FaqPage';
import IndexPage from './IndexPage';
import LogoutPage from './LogoutPage/LogoutPage';
import NotFoundPage from './NotFoundPage';
import OrderPage from './OrderPage';
import PaymentFailurePage from './PaymentFailurePage/PaymentFailurePage';
import PaymentSuccessPage from './PaymentSuccessPage/PaymentSuccessPage';
import ProductPage from './ProductPage';
import ProfilePage from './ProfilePage';

export const ROUTE_ROOT = '/';
export const ROUTE_CONTACTS = '/contacts';
export const ROUTE_CATEGORIES = '/categores';
export const ROUTE_ABOUT = '/about';
export const ROUTE_FAQ = '/faq';
export const ROUTE_CART = '/cart';
export const ROUTE_PAYMENT_SUCCESS = '/payment_success';
export const ROUTE_PAYMENT_FAILURE = '/payment_failure';
export const ROUTE_PROFILE = '/profile';
export const LOGOUT = '/logout';
export const COMPLETE_REGISTER = '/complete_registration';
export const ROUTE_PRODUCT = '/product';
export const ROUTE_CATEGORY = '/category';
export const ROUTE_ORDER = '/order';
export const ROUTE_CALLBACK = '/callback';

export const ROUTE_NOT_FOUND = '*';


export const publicRoutes = [
    {
      path: COMPLETE_REGISTER,
      element: <CompleteRegisterPage />
    },
    {
      path: LOGOUT,
      element: <LogoutPage />
    },
    {
      path: ROUTE_PAYMENT_SUCCESS,
      element: <PaymentSuccessPage />
    },
    {
      path: ROUTE_PAYMENT_FAILURE,
      element: <PaymentFailurePage />,
    },
    {
        path: ROUTE_CALLBACK,
        element: < CallbackPage />,
    },
    {
        path: ROUTE_ROOT,
        element: <IndexPage />,
    },
    {
        path: ROUTE_CONTACTS,
        element: <ContactsPage />,
    },
    {
        path: ROUTE_ABOUT,
        element: <AboutUsPage />,
    },
    {
        path: ROUTE_FAQ,
        element: <FaqPage />,
    },
    {
        path: ROUTE_CART,
        element: <Cart />,
    },
    {
        path: ROUTE_PRODUCT,
        element: <ProductPage />,
    },
    {
        path: ROUTE_CATEGORIES,
        element: <CategoriesPage />,
    },
    {
        path: ROUTE_CATEGORY,
        element: <CategoryPage />,
    },
    {
        path: ROUTE_PROFILE,
        element: <ProfilePage />,
    },
    {
        path: ROUTE_ORDER,
        element: <OrderPage />,
    },
    {
        path: ROUTE_NOT_FOUND,
        element: <NotFoundPage />,
    },

];
