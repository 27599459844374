const getErrorNotify = (error:any) => {
  let errorString = "";
  if (error?.response?.data?.message) {
    errorString = error.response.data.message;
  } else if (Array.isArray(error?.response?.data?.detail)) {
    error.response.data.detail.forEach((el:any) => {
      errorString = errorString + el.msg + " <br />";
    });
  } else if (typeof error.response?.data?.detail === "string") {
    errorString = error.response.data.detail;
  } else {
    errorString = "Произошла ошибка";
  }
  return <p dangerouslySetInnerHTML={{ __html: errorString }} />;
};

export { getErrorNotify };
