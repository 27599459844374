import * as React from 'react';
import './Shadow.scss';
import { useSelector, useDispatch } from 'react-redux';
import {hide} from '../../store/reducers/basketSlice';

const Shadow = () => {
    const modalOpened = useSelector((state) => state.modal.opened);
    const addressModalOpened = useSelector((state) => state.addressModal.opened);
    const basketOpened = useSelector((state) => state.basket.opened);
    const dispatch = useDispatch();
    const closeBasket = () => {
        dispatch(hide());
    };

    return (
        <div
            className={`shadow ${basketOpened || modalOpened || addressModalOpened ? 'active' : ''}`}
            onClick={closeBasket}
        />
    );
};

export default Shadow;
