import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../api/http";
import { getErrorNotify } from "../../helpers/getErrorNotify";
import { useCompleteRegisteration } from "../../hooks/useCompleteRegister";
import { showModalAddress } from "../../store/reducers/addressModalSlice";
import { RootState } from "../../store/store";
import { AddressI, CurrentUserI, OrderI, ProfileI } from "../../types/types";
import "./ProfilePage.scss";

const ProfilePage = () => {
  useCompleteRegisteration()
  const [profile, setProfile] = useState<ProfileI | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<CurrentUserI | null>(null);
  const [address, setAddress] = useState<AddressI[] | null>(null);
  const [orders, setOrders] = useState<OrderI[] | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const modalOpened = useSelector<RootState>((state) => state.addressModal.opened)
  const [values, setValues] = useState<AddressI>({} as AddressI);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProfile = async () => {
      await http
        .get("/account/users/me")
        .then((response) => {
          setProfile(response?.data);
        })
        .catch((error) => console.error(error));
    };
    fetchProfile();
  }, []);
  
  useEffect(() => {
    const fetchAccount = async () => {
      await http
        .get("/account/current_account")
        .then((response) => {
          setCurrentUser(response?.data);
        })
        .catch((error) => console.error(error));
    };
    fetchAccount();
  }, []);
  const fetchAddress = async () => {
    await http
      .get("/addresses")
      .then((response) => {
        setAddress(response?.data);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    fetchAddress();
  }, [modalOpened]);

  const fetchOrders = async () => {
    await http
      .get("/orders/account/orders")
      .then((response) => {
        setOrders(response?.data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const getDateString = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${month} ${day} ${hours}:${minutes}`;
  };

  const deleteItem = async (id: number) => {
    await http
      .delete("/addresses/" + id)
      .then((response) => {
        toast.success(response.data.message);
        fetchAddress();
      })
      .catch((error) => toast.error(getErrorNotify(error)));
  };
  const postAddress = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await http
      .post(
        "/addresses",
        {
          account_id: profile?.account?.id,
          ...values,
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        fetchAddress();
        //@ts-ignore
        setValues({});
      })
      .catch((error) => toast.error(getErrorNotify(error)));
  };

  const setFormData = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  useEffect(() => {
    if (!tab) {
      setSearchParams({ tab: "main" });
    }
  }, [tab]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const timeStamp = Number(localStorage.getItem("timeStamp"));
  //     if (Date.now() - timeStamp >= 86400000) {
  //       localStorage.removeItem("timeStamp");
  //       localStorage.removeItem("token");
  //       navigate("/");
  //       // toast.error('token was expired, relogin please')
  //       dispatch(showModal());
  //     }
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <section className="profilePage container">
      <div className={"sidebar" + (sidebarOpen ? ' sidebarOpen' : '')}>
        <div>
        <Link className="primaryLink" to="/profile?tab=main">
          Личный кабинет
        </Link>
        <Link to="/cart">Корзина</Link>
        <Link to="/profile?tab=orders">Заказы</Link>
        <Link to="/profile?tab=profile">Профиль</Link>
        <Link to="/profile?tab=address">Адреса</Link>
        <button onClick={() => setSidebarOpen(!sidebarOpen)} className='arrow'>
          <span />
          <span />
          <span />
        </button>
        </div>
      </div>
      {tab === "profile" && (
        <div className="wrapper profileInfo">
          <div className="mainBlock">
            <div className="block">
              <img src="icons/profile.png" alt="профиль" />
              <div>
                <p style={{ marginBottom: "8px" }}>Физическое лицо</p>
                <p style={{ marginBottom: "8px" }} className="bold">
                  {`${profile?.last_name ? profile.last_name : ""} ${
                    profile?.first_name ? profile.first_name : ""
                  } ${profile?.patronymic ? profile.patronymic : ""}`}
                </p>
              </div>
            </div>
          </div>
          <div className="profileInfoBottom">
            <div className="block profileInfoBlock">
              <p className="bold">Почта</p>
              <p>{profile?.email}</p>
            </div>
            <div className="block profileInfoBlock">
              <p className="bold">Контактные телефоны</p>
              <p><span >Основной: </span>{currentUser?.phone_number ? currentUser.phone_number : '-'}</p>
              <p><span >Дополнительный: </span>{currentUser?.extra_phone_number ? currentUser.extra_phone_number : '-'}</p>
            </div>
          </div>
        </div>
      )}
      {tab === "main" && (
        <div className="wrapper profile">
          <div className="mainBlock">
            <div className="block">
              <img src="icons/profile.png" alt="профиль" />
              <div>
                <h2 className="heading">Профиль</h2>
                <p style={{ marginBottom: "8px" }} className="bold">
                  {profile?.first_name ? profile?.first_name : "--"}
                </p>
                <p>
                  E-mail:
                  <span className="bold"> {profile?.email}</span>
                </p>
                <p>
                  Тел:
                  <span className="bold"> {currentUser?.phone_number}</span>
                </p>
                <Link to="/logout" className="logout">Выйти</Link>
              </div>
            </div>
          </div>
          <Link className="secondaryBlock block" to="/cart">
            Корзина
            <img className="invert" src="icons/basket.png" alt="корзина" />
          </Link>
          <Link className="secondaryBlock block" to="/profile?tab=orders">
            Заказы
            <img src="icons/stock.png" alt="заказы" />
          </Link>
          <Link className="secondaryBlock block" to="/profile?tab=address">
            Адреса
            <img src="icons/map-tag.png" alt="адреса" />
          </Link>
        </div>
      )}
      {tab === "address" && (
        <>
          <div className="tableWrapper wrapper block">
            <table>
              <thead>
                <tr>
                  <th>Адрес</th>
                  <th>Квартира</th>
                  <th>Подъезд</th>
                  <th>Домофон</th>
                  <th>Этаж</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {address?.map((el) => {
                  return (
                    <tr className="addresBlock" key={`addr_${el.address_string}`}>
                      <td>{el.address_string}</td>
                      <td>{el.flat}</td>
                      <td>{el.entrance}</td>
                      <td>{el.intercom}</td>
                      <td>{el.floor}</td>
                      <td>
                        <button
                          className="deleteButton"
                          onClick={() => deleteItem(el.id)}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
              <button onClick={() => dispatch(showModalAddress())} className="submitButton">Добавить адрес</button>
          </div>
        </>
      )}
      {tab === "orders" && (
        // <div style={{maxWidth: '700px', margin: '0 auto'}} className="tableWrapper wrapper block">
        //   <table style={{minWidth: '600px'}}>
            
        <div style={{maxWidth: 'min(900px, calc(100% - 5rem))'}} className="tableWrapper wrapper block">
          <table cellPadding={"100px"} cellSpacing={"100px"}>
            <thead>
              <tr>
                <th style={{maxWidth: 'calc(100% / 12'}}>№</th>
                <th style={{maxWidth: 'calc(100% / 12 * 3'}}>Время</th>
                <th style={{maxWidth: 'calc(100% / 12 * 4'}}>Статус</th>
                <th style={{maxWidth: 'calc(100% / 12 * 4'}}></th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((el) => {
                return (
                  <tr key={`ord_${el.id}`}>
                    <td>{el.id}</td>
                    <td>{getDateString(el.time)}</td>
                    <td>{el.status}</td>
                    <td style={{ textAlign: "right" }}>
                      <Link style={{whiteSpace: 'nowrap', marginRight: '0'}} className="submitButton" to={`/order?id=${el.id}`}>Перейти к заказу</Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
};

export default ProfilePage;
