import * as React from "react";
import "./FaqPage.scss";
import { Helmet } from "react-helmet";

const FaqPage = () => (
  <main>
    <Helmet>
      <title>Декоратория - Частые вопросы</title>
    </Helmet>
    <section className="faq">
      <h2>Часто задаваемые вопросы</h2>
      <div className="wrapper">

        <article className="item">
          <h3>Как внести изменения в заказ с доставкой или самовывозом?</h3>
          <p>
            Иногда планы меняются. Не волнуйтесь, вы можете быстро изменить заказ
            самостоятельно на нашем сайте в разделе "Отслеживание и управление
            заказом".
          </p>
        </article>
        <article className="item">
          <h3>Как внести изменения в заказ с доставкой или самовывозом?</h3>
          <p>
            Иногда планы меняются. Не волнуйтесь, вы можете быстро изменить заказ
            самостоятельно на нашем сайте в разделе "Отслеживание и управление
            заказом".
          </p>
        </article>
        <article className="item">
          <h3>Как внести изменения в заказ с доставкой или самовывозом?</h3>
          <p>
            Иногда планы меняются. Не волнуйтесь, вы можете быстро изменить заказ
            самостоятельно на нашем сайте в разделе "Отслеживание и управление
            заказом".
          </p>
        </article>
        <article className="item">
          <h3>Как внести изменения в заказ с доставкой или самовывозом?</h3>
          <p>
            Иногда планы меняются. Не волнуйтесь, вы можете быстро изменить заказ
            самостоятельно на нашем сайте в разделе "Отслеживание и управление
            заказом".
          </p>
        </article>
        <article className="item">
          <h3>Как внести изменения в заказ с доставкой или самовывозом?</h3>
          <p>
            Иногда планы меняются. Не волнуйтесь, вы можете быстро изменить заказ
            самостоятельно на нашем сайте в разделе "Отслеживание и управление
            заказом".
          </p>
        </article>
        <article className="item">
          <h3>Как внести изменения в заказ с доставкой или самовывозом?</h3>
          <p>
            Иногда планы меняются. Не волнуйтесь, вы можете быстро изменить заказ
            самостоятельно на нашем сайте в разделе "Отслеживание и управление
            заказом".
          </p>
        </article>

       </div>
    </section>
  </main>
);
export default FaqPage;
